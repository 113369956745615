import React from 'react';
import Layout from "../components/Layout";
import { Link } from 'gatsby';

export default function FrequentlyAskedQuestions(){
    return (
        <Layout>
            <div>
            <h1 style={{color:'#3399f3', marginBottom:'30px'}}>Frequently Asked Questions</h1>
            <div class="overflow-auto" style={{fontSize:'1.2em'}} className='faqContent'>
                <p>Below are some of the most commonly asked questions we are asked about the Basic Cognitive study.</p> 
                <ul>
                    <li><a href="#how-to-participate" className='callLink'>How can I participate in your study?</a></li> 
                    <li><a href="#why-did-someone-call-me-from-your-study" className='callLink'>Why did someone call me from your study?</a></li> 
                    <li><a href="#how-did-you-get-my-address-andor-phone-number" className='callLink'>How did you get my address and/or phone number?</a></li> 
                    <li><a href="#who-is-conducting-the-basic-cognitive-project" className='callLink'>Who is conducting the BASIC Cognitive project?</a></li> 
                    <li><a href="#what-is-the-research-study-about-and-how-is-it-being-conducted" className='callLink'>What is the research study about and how is it being conducted?</a></li> 
                    <li><a href="#how-do-i-know-what-your-field-interviewers-look-like" className='callLink'>How do I know what your field interviewers look like?</a></li> 
                    <li><a href="#is-my-information-private" className='callLink'>Is my information private?</a></li> 
                    <li><a href="#why-is-the-research-study-being-done-here-in-nueces-county" className='callLink'>Why is the research study being done here in Nueces County?</a></li> 
                </ul> 
                <h2><a href="#how-to-participate" id="how-to-participate" className='callLink'>How can I participate in your study?</a></h2> 
                <p>There are several ways to participate in our research study. Some Nueces County residents may have received a letter in the mail, a visit or phone call at home, or a referral from another participant. Click this <a href="https://basiccognitive.org/Recruitment Flyer New.pdf" className='callLink' target="_blank" rel="noreferrer">link</a> if you are interested in participating and have not been contacted directly by a researcher.</p>
                <h2><a href="#why-did-someone-call-me-from-your-study" id="#why-did-someone-call-me-from-your-study" className='callLink'>Why did someone call me from your study?</a></h2>
                <p>BASIC Cognitive has temporarily moved from visiting residents at their homes to contacting them over the phone. We want to reassure you that we are not soliciting. The participation of eligible residents is completely voluntary. If you have questions about a phone call you received and would like immediate assistance, please call 361-444-7597 or e-mail <a href="mailto:umhs-basiccognitive@med.umich.edu" className='callLink'>umhs-basiccognitive@med.umich.edu</a>.</p>
                <h2><a href="#how-did-you-get-my-address-andor-phone-number" id="how-did-you-get-my-address-andor-phone-number" className='callLink'>How did you get my address and/or phone number?</a></h2> 
                <p>Addresses and phone numbers are selected from publicly available information. Letters are mailed to randomly selected households informing residents that study staff will be contacting them to determine if someone in the home is 65 or older.</p> 
                <h2><a href="#who-is-conducting-the-basic-cognitive-project" id="who-is-conducting-the-basic-cognitive-project" className='callLink'>Who is conducting the BASIC Cognitive project?</a></h2> 
                <p>BASIC Cognitive is being conducted by the University of Michigan in partnership with the <a href="http://www.cctexas.com/departments/health-district" className='callLink'>Corpus Christi-Nueces County Public Health District</a>. We have also partnered with <a href="http://www.sci.tamucc.edu/index.html" target='_blank' rel='noopener noreferrer' className='callLink'>Texas A&amp;M Corpus Christi</a>.</p> <p>The project is sponsored by the National Institutes of Health (NIH) and <a href="https://medicine.umich.edu/dept/neurology/lewis-morgenstern-md"  target='_blank' rel='noopener noreferrer'  className='callLink'>Lewis B. Morgenstern, MD</a> is the Principal Investigator.</p> 
                <h2><a href="#what-is-the-research-study-about-and-how-is-it-being-conducted" id="what-is-the-research-study-about-and-how-is-it-being-conducted" className='callLink'>What is the research study about and how is it being conducted?</a></h2> 
                <p>The goal of BASIC Cognitive is to learn how memory changes as people get older. We will also gain more knowledge about caregiving. We want to understand how caregiver needs are being met and what areas may require more attention.</p> <p>We have randomly selected a sample of over 14,000 households throughout Nueces County. A letter will be sent by mail notifying the residents that they have been selected to receive a visit. When we mail the letters, we have no idea who lives in the residence. The only way to potentially participate in our study is if one of our field interviewers contacts them or they receive a letter. We are looking for participants age 65 and over. If someone receives a letter at their household and there is no one 65 or older in their home, they can call us and we can mark them off our list. Currently we are unable to recruit participants by any other method.</p> <p>Eligible participants will do a brief memory screening that takes approximately 15 minutes. The results of the screening will determine if the person can participate in a bigger part of the study which would include various mental exercises that measure cognitive function. This study is at absolutely no cost to the resident. Everyone who does the screening will receive the results so that they can share it with their doctor if they so choose. We will also provide a list of local and national resources.</p> 
                <h2><a href="#how-do-i-know-what-your-field-interviewers-look-like" id="how-do-i-know-what-your-field-interviewers-look-like" className='callLink'>How do I know what your field interviewers look like?</a></h2> 
                <p>Whether they contact you in-person or by telephone, all University of Michigan Field Interviewers will clearly identify themselves. In-person, they carry a University of Michigan ID badge and a BASIC Cognitive badge that shows their affiliations with the Corpus Christi-Nueces County Public Health District. Both badges show their name and picture. <Link to="/meetOurTeam" className='callLink' target='_blank' rel='noopener noreferrer' >You can see our current staff here.</Link> To confirm the legitimacy of an interviewer, please call 361-257-1239. We are available Monday through Friday 9:00 am - 7:00 pm. You can also e-mail <a href="mailto:umhs-basiccognitive@med.umich.edu" className='callLink'>umhs-basiccognitive@med.umich.edu</a>.</p> 
                <h2><a href="#is-my-information-private" id="is-my-information-private" className='callLink'>Is my information private?</a></h2> 
                <p>All our research methods are designed to ensure that your confidentiality is protected. Thorough safeguards are built into everything we do and all employees who work for our organization adhere to strict protocols and procedures.</p> 
                <h2><a href="#why-is-the-research-study-being-done-here-in-nueces-county" id="why-is-the-research-study-being-done-here-in-nueces-county" className='callLink'>Why is the research study being done here in Nueces County?</a></h2> 
                <p>Dr. Lewis Morgenstern, study director has been conducting research in the Corpus Christi area since 1996. You may have heard about our other local study, <a href="https://medicine.umich.edu/dept/neurology/research/stroke/basic-project" className='callLink'>BASIC (Brain Attack Surveillance in Corpus Christi) project</a>. BASIC has been identifying and studying stroke patients in Nueces County since 1999. Over 6,000 people have participated in this study. Through its partnership with the community, BASIC has resulted in many important scientific discoveries <a href="https://medicine.umich.edu/dept/neurology/research/stroke/basic-project" className='callLink'>You can find more information on BASIC or the other research projects here.</a></p> <p>Dr. Morgenstern believes Nueces County is a great place to do medical research. He says, “People here really care about their families and their neighbors.”</p> 
            </div>
            </div>
        </Layout>
    )
}